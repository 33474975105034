import C from './constants';

export const navigationStore = (state = {page_menus: []}, action) => {
    switch (action.type) {
        case C.ACTIVE_MENU:
            return {
                ...state,
                page_menus:action.page_menus
            }
        case C.UPDATE_MENU_PIPELINE:
            const pipeline = action.pipeline;
            let menus = state.page_menus;
            const updatedItems = menus.map((item) => {
                if(item.pipeline.pk === pipeline.pk){
                    let newItem = item;
                    newItem.pipeline = pipeline;
                    return newItem
                }else{
                    return item
                }
            });
            return { ...state, page_menus: updatedItems };
        default:
            return state
    }
}

export const reportsNavigations = (state = {reload: true}, action) => {
    switch (action.type) {
        case C.LOAD:
            return {
                ...state,
                reload:action.reload
            }
        default:
            return state
    }
}

export const pipeline = (state = {pipeline : null}, action) => {
    switch (action.type) {
        case C.UPDATE_PIPELINE:
            return {
                ...state,
                pipeline: action.pipeline
            }
        default:
            return state
    }
}

export const transaction = (state = {transaction : null}, action) => {
    switch (action.type) {
        case C.UPDATE_TRANSACTION:
            return {
                ...state,
                transaction: action.transaction
            }
        default:
            return state
    }
}


export const site = (state = {site : {}}, action) => {
    switch (action.type) {
        case C.SET_SITE:
            return {
                ...state,
                site: action.site
            }
        default:
            return state
    }
}

export const buckets = (state = {buckets : []}, action) => {
    switch (action.type) {
        case C.SET_BUCKETS:
            return {
                ...state,
                buckets: action.buckets
            }
        default:
            return state
    }
}

export const bucket = (state = {bucket : ""}, action) => {
    switch (action.type) {
        case C.SET_BUCKET:
            return {
                ...state,
                bucket: action.bucket
            }
        default:
            return state
    }
}