import axios from "axios";

const next = window.location.pathname;
let path = "";
console.log(next);
if(next !=="" && next !=="/"){
  path = "#next="+next
}

const TokenInterceptors = function (history) {
  axios.interceptors.request.use(
    (config) => {
      if (!config.url.includes("login") && !config.url.includes("register") && !config.url.includes("s3.amazonaws.com")) {
        const authToken = localStorage.getItem("token");
        if (!!authToken) config.headers.Authorization = `Bearer ${authToken}`;
      }
      if (!config.url.endsWith("/") && !config.url.includes("?")) {
        config.url = config.url + "/";
      }
      return config;
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        console.log(window.location.href);
        history.push('/#/login'+path);
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        console.log(window.location.href);
        history.push('/#/login'+path);
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
}

export default TokenInterceptors;