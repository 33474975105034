import React, { Component } from "react";
import {Route, HashRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./scss/style.scss";
import tokenInterceptor from "./token.interceptor";
// import CreateAccount from './views/order/CreateAccount';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const createHistory = require("history").createBrowserHistory;

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/Login"));
const ForgotPassword = React.lazy(() => import("./views/auth/ForgotPassword"));
// const Signup = React.lazy(() => import("./views/order/Signup"));
const PrivateRoute = React.lazy(() => import("./containers/PrivateRoute"));
// const CreateAccount = React.lazy(() => import('./views/order/CreateAccount'));
const Register = React.lazy(() => import("./views/auth/Signup"));
const ChangePassword = React.lazy(() => import("./views/auth/ChangePassword"));
const Page404 = React.lazy(() => import("./views/page404/Page404"));
const Page500 = React.lazy(() => import("./views/page500/Page500"));
const Dashboard = React.lazy(() => import("./views/dashboard/DashboardNew"));
const Pipeline = React.lazy(() => import("./views/pipelines/Pipeline"));
// const AwsLogin = React.lazy(() => import("./views/order/AwsLogin"));
const Location = React.lazy(() => import("./views/location/Location"));
const history = createHistory();
tokenInterceptor(history);
class App extends Component {
  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <PrivateRoute
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
             <PrivateRoute
              path="/"
              name="Dashboard"
              render={(props) => <Dashboard {...props} />}
            />
             <PrivateRoute
              path="/pipeline"
              name="Pipeline"
              render={(props) => <Pipeline {...props} />}
            />
             <PrivateRoute
              path="/location"
              name="Location"
              render={(props) => <Location {...props} />}
            />
            <PrivateRoute
              path="/change-password"
              name="ChangePasword"
              render={(props) => <ChangePassword {...props} />}
            />
          </Switch>
        </React.Suspense>
        <ToastContainer />
      </Router>
    );
  }
}

export default App;
