const constants = {
    ACTIVE_MENU: "ACTIVE_MENU",
    UPDATE_MENU_PIPELINE: "UPDATE_MENU_PIPELINE",
    SET_STORE:"SET_STORE",
    LOAD:"LOAD",
    UPDATE_PIPELINE:"UPDATE_PIPELINE",
    SET_SITE: "SET_SITE",
    UPDATE_TRANSACTION: "UPDATE_TRANSACTION",
    SET_BUCKETS: "SET_BUCKETS",
    SET_BUCKET: "SET_BUCKET"

}





export default constants



