import { createStore, combineReducers } from 'redux'
import { navigationStore, reportsNavigations, pipeline, site, transaction, buckets, bucket } from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
    key: 'root',
    storage,
  }


const rootReducer = combineReducers({navigationStore, reportsNavigations, pipeline, site, transaction, buckets, bucket});
const persistedReducer = persistReducer(persistConfig, rootReducer)


let store = createStore(persistedReducer);


export const persistor = persistStore(store);
export default store;